<template>
    <div class="anDiv">
      <el-row class="anRow" :gutter="40" style="margin-top:20px" v-for="(item,i) in newList" :key="i">
          <el-col :span=6 >
              <img :src="item.imgUrl"/>
          </el-col>
          <el-col :span=18 class="anRowLeft">
              <div class="anDiv2">{{item.title}}</div>
              <div class="anDiv3">{{item.content}}</div>
              <div class="anMore" @click="moreClick(item)">查看更多 ></div>
              <div class="anDiv4">{{item.date}}</div>
          </el-col>
      </el-row>
      <el-row class="anRowFooter">
          <!-- <el-col :span=24>
              <el-pagination background  
              layout="prev, pager, next"  
              :total="count" 
              :page-size="5"
              @current-change=currentPageClick

              > -->
              <!-- </el-pagination> -->
          <!-- </el-col> -->
      </el-row>
    </div>
</template>
<script>
export default {
    name:"exampleSay",
    data(){
        return{
            temp:{date:"2019-01-22",imgUrl:require("../../static/aboutNews1.png"),
                title:"VANTIQ助力企业数字化转型与发展",
                content:`ANTIQ应用平台让每家企业都能够快速构建、部署和运行，基于任何类型的数据流的实时、事件驱动基于任何类型的数据流的实时、
                  事件驱动基于任何类型的数据流的实时、事件驱动基于任何类型的数据流的实时、事件驱动`,
            },
            listData:[],
            count:21,
            currentPage:1,
            newList:[]
        }
        
    },
    methods:{
        init(){
            let list=[]
            for(let i=0;i<21;i++){
                let item=JSON.parse(JSON.stringify(this.temp))
                item.id=i+1
                item.title=i+"_"+item.title
                list.push(item)
            }
            // this.listData=list
            // this.count=list.length
            // this.currentPageClick(1)
        
        },
        currentPageClick(page){
            this.newList=[]
            // this.currentPage=page
            let start=(page-1)*5
            let list=[]
            for(let i=0;i<5;i++){
                if(start+i+1<=this.listData.length){
                    let item=this.listData[start+i]
                    list.push(item)
                }
                
            }
            this.newList=list
            this.$forceUpdate()
        },
       moreClick(row){
           this.$router.push({
               name:"aboutNewsInfo",
               query:{
                    name:row.title,
                    type:"example",
                    page:"say"
               }
           })
       }
    },
    created(){
        this.init()
    }
}
</script>
<style>
.anDiv{
    padding-top:60px;
}
.anRow{
    padding:20px 0;
    border:1px solid #DBE3EF
}
.anRow .anxie{
    height: 48px;
    display: inline-block;
    border-left: 1px solid #848484;
    transform: skew(-20deg);
    margin: 0 15px;
}
.anRow .anDiv1{
    font-size:14px;
    line-height: 24px;
}
.anRow .anRowRight{

text-align:center
}

.anRow .anMore
{
    font-size:14px;
    color:#3C6AFB;
    margin-top: 20px;
    cursor:pointer;
}
.anRowLeft .anDiv2{
    height: 32px;
    font-size: 18px;
    color: #404756;
    line-height: 32px;
    position:relative
}
.anRowLeft .anDiv3{
    height: 26px;
    font-size: 14px;
    color: rgba(64, 71, 86, 0.7);
    line-height: 26px;
    white-space:nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top:20px

}
.anRowLeft .anDiv4{
    height: 14px;
    font-size: 14px;
    color: #404756;
    line-height: 14px;
    position: absolute;
    top: 30px;
    right: 20px;
}
.anRowFooter{
    text-align: right;
    padding: 60px 0;
}
</style>
